import React from 'react'
import styled from 'styled-components'
import { Fade } from 'react-reveal'
import {
  Box,
  Container,
  Flex,
  Link as A,
  Text,
  theme
} from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import { Headline, FaqHeader, Background } from 'components/Content'
import 'material-expansion-panel/dist/material-expansion-panel.css'
import 'material-expansion-panel/dist/material-expansion-panel.min.css'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'
import ExpansionPanel from 'material-expansion-panel'

A.link = A.withComponent(Link)

const Base = styled(Container)`
  display: grid;
  grid-gap: 1rem;
  ${theme.mediaQueries.md} {
    grid-gap: 0.5rem;
    max-width: 50vw;
    grid-template-columns: repeat(1, 1fr);
  }
  > div {
    height: 65px;
    padding: 5px 30px 0px 0px;
  }
  span.text {
    font-size: 19px !important;
  }
  @media (max-width: 769px) {
    width: 100vw;
    > div {
      height: 85px;
    }
  }
  div > span {
    font-size: 20px;
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    margin-right: -50px;
  }
`
const title = 'FAQ - Coding Sastra'

export default ({ data }) => {
  let faqs = data.allContentfulFaq.nodes
  return (
    <Layout
      title={title}
      desc={data.site.siteMetadata.description}
      keywords={data.site.siteMetadata.keywords}
      path="/faq/"
      img="/cards/workshops.png"
    >
      <Nav />
      <Social />
      <Background px={3} pt={4}>
        <FaqHeader pt={[4, 5, 6]}></FaqHeader>
        <Flex justify="center" color="white">
          <Container width={1} py={[4, 5]} align={['left', 'center']}>
            <Headline px={3} mt={[2, 2]} mb={[2, 2]} mx="auto">
              <Fade bottom>FREQUENTLY ASKED QUESTIONS (FAQS)</Fade>
            </Headline>
          </Container>
        </Flex>
      </Background>
      <Base px={3} py={[2, 3]}>
        {faqs.map(faq => {
          return (
            <ExpansionPanel
              title={faq.question}
              expandedTitle={faq.question}
              key={faq.id}
            >
              <Box mt={2} mb={3}>
                <Text ml={5} color="slate">
                  {faq.answer.answer}
                </Text>
              </Box>
            </ExpansionPanel>
          )
        })}
      </Base>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query allFaqs {
    allContentfulFaq(sort: { fields: id, order: DESC }) {
      nodes {
        id
        question
        answer {
          answer
        }
      }
    }
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
